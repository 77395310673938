import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import Form from '../../components/Form'
import { useFormData } from '../../hooks/gravityForms'
import { H1 } from '../../components/Headings'
import Container from '../../components/Container'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../helpers'
import Content from '../../components/Post/Content'

const PageTemplate = ({ data, location }) => {
  const page = data.allWpPage.nodes[0]
  // const [cooksLogoTransparent] = data.allFile.nodes

  const allFormData = useFormData()

  const handleError = ({ values, error, reset }) => {
    //handle error
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    //handle success
  }

  const sidebarContent = page?.contactUsSidebar?.sidebarContent
    ? page?.contactUsSidebar?.sidebarContent
    : null

  return (
    <>
      <Layout location={location}>
        <Seo post={formatYoastSeo(page)} />
        <div tw="w-full bg-gradient-to-b from-blue-100 via-white to-white">
          <Container tw="grid grid-cols-1 md:(grid-cols-2) col-gap-12 py-12">
            <div tw="pb-2 md:(pb-0)">
              <H1 tw="mb-5 text-center md:(text-left)">{page.title}</H1>
              <div
                tw="prose mb-10 text-gray-800 text-center md:(text-left)"
                dangerouslySetInnerHTML={{ __html: page.content }}
              />
              <Form
                id={3}
                allFormData={allFormData}
                successCallback={handleSuccess}
                errorCallback={handleError}
              />
            </div>

            {sidebarContent !== null && (
              <Content
                css={[
                  tw`text-center max-w-sm mx-auto`,
                  `& > .wp-block-image {
                  margin: 1.5em 0; 
                }`,
                ]}
              >
                <div dangerouslySetInnerHTML={{ __html: sidebarContent }} />
                <div tw="bg-gradient-to-b from-blue-500 to-blue-700 p-10 rounded-lg mt-8">
                  <img
                    src="/110-satisfaction-icon-white.png"
                    tw="w-full mb-5"
                    loading="lazy"
                  />
                  <div tw="text-white leading-relaxed text-center">
                    <p>
                      Our 110% Satisfaction Guarantee is designed to give you
                      confidence that when you sign an agreement with Cook’s,
                      you’ll be completely satisfied with our service. As long
                      as you are a Cook’s customer, your pest control technician
                      will return as often as needed to ensure your
                      satisfaction.
                    </p>
                  </div>
                </div>
              </Content>
            )}
          </Container>
        </div>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allFile(filter: { relativePath: { eq: "cooks-logo-transparent-bg.png" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
        seo {
          ...PostSEO
        }
        contactUsSidebar {
          sidebarContent
        }
      }
    }
  }
`
